<template>
    <div class="contain">
      <p class="title">新石器准时达隐私政策</p>
      <p>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp 本政策适用于新石器慧通（北京）科技有限公司（注册地址：北京市北京经济技术开发区荣华中路10号1幢17层1703（北京自贸试验区高端产业片区亦庄组团），应用名称：新石器准时达）提供的产品和服务及其相关功能，包含APP平台、小程序等。</p>
      <p>更新日期：2022年06月01日</p>
      <p class="m30">
        “新石器准时达”尊重并保护所有使用服务用户的个人隐私权，会按照本隐私权政策的规定使用和披露您的个人信息。但我们将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，我们不会将这些信息对外披露或向第三方提供。您在同意“新石器准时达”服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于“新石器准时达”服务使用协议不可分割的一部分。
      </p>
      <p class="m30">其中要点如下：</p>
      <p class="m60">
        1、为了便于您了解您在使用我们的服务时，我们需要收集的信息类型与用途，我们将结合具体服务向您逐一说明。
      </p>
      <p class="m60">
        2、为了向您提供服务所需，我们会按照合法、正当、必要的原则收集您的信息。
      </p>
      <p class="m60">
        3、如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意或确认第三方已经征得您的同意。
      </p>
      <p class="m60">
        4、如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性；如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。
      </p>
      <p class="m60">
        5、您可以通过本协议介绍的方式访问和管理您的信息、注销“新石器准时达”账户或进行投诉举报。为了遵守国家法律法规及监管规定，也为了向您提供服务及提升服务质量，我们需要收集、存储、使用您的信息。您同意我们按照本协议约定处理您的信息，以便您享受优质、便捷的服务，同时更好地保护您的账户安全。
      </p>
  
      <p>一、我们如何收集信息</p>
      <p class="m30">
        在您使用“新石器准时达”以下各项业务功能（以下简称“服务”）的过程中，我们需要收集您的一些信息，用以向您提供服务、提升我们的服务质量、保障您的账户符合国家法律法规及监管规定：
      </p>
      <p class="m30">1、注册</p>
      <p class="m60">注册账号</p>
      <p class="m60">
        当您注册时，您需向我们提供您本人的手机号码，我们将通过发送短信验证码的方式来验证您的身份是否有效。您的账户名为您的默认昵称，您无需提供其他个人信息方可浏览业务服务界面。您提供的上述信息，将在您使用“新石器准时达”产品和服务期间持续授权我们使用。
      </p>
      <p class="m60">
        您提供的上述信息，将在您使用“新石器准时达”产品和服务期间持续授权我们使用。
      </p>
      <p class="m30">2、安全管理</p>
      <p class="m60">
        为了保障向您提供的服务的安全稳定运行,预防交易和资金风险，预防病毒、木马程序或其他恶意程序、网站,我们需要记录您使用的“新石器准时达”服务类别、方式及设备型号、IP地址、设备软件版本信息、设备识别码、设备标识符、所在地区、唯一识别码（IMEI、IDFA、 Android ID、MAC、OAID、IMIS、应用安装列表及其他设备相关信息）以及其他与“新石器准时达”服务相关的日志信息。如您不同意我们记录前述信息,可能无法完成风控验证。
      </p>
      <p class="m30">
        3、根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意:
      </p>
      <p class="m60">
        （1）与履行国家法律法规及行业主管部门有关规定的义务相关的；
      </p>
      <p class="m60">（2）与国家安全、国防安全直接相关的；</p>
      <p class="m60">（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p class="m60">（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p class="m60">
        （5）出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </p>
      <p class="m60">（6）所收集的个人信息是您自行向社会公众公开的；</p>
      <p class="m60">
        （7）从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；
      </p>
      <p class="m60">（8）根据您的要求签订和履行合同所必需的；</p>
      <p class="m60">
        （9）用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置服务的故障，识别、处置金融产品或服务中的欺诈或被盗用。
      </p>
      <p class="m30">4、其他</p>
      <p class="m60">
        请您理解，我们向您提供的服务是不断更新和发展的。如您选择使用了前述说明当中尚未涵盖的其他服务，基于该服务我们需要收集您的信息的，我们会通过页面提示、交互流程、协议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本协议以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，您可能无法使用某项或某部分服务，但不影响您使用我们提供的其他服务。此外，第三方主体可能会通过“新石器准时达”APP向您提供服务。当您进入第三方主体运营的服务页面时，请注意相关服务由第三方主体向您提供。涉及到第三方主体向您收集个人信息的，建议您仔细查看第三方主体的隐私协议或协议约定。
      </p>
  
      <p>二、我们如何使用Cookie、Proxy等技术</p>
      <p class="m30">
        为使您获得更轻松的访问体验，您访问“新石器准时达”网站或使用“新石器准时达”提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。这些数据文件可能是Cookie，FlashCookie，您的浏览器或关联应用程序提供的其他本地存储（以下简称“Cookie”）。请您理解，我们的某些服务只能通过使用Cookie才可得到实现。
      </p>
      <p class="m60">
        a.在您未拒绝接受cookies的情况下，“新石器准时达”会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的“新石器准时达”平台服务或功能。“新石器准时达”使用cookies可为您提供更加周到的服务。
      </p>
      <p class="m60">
        b.您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的“新石器准时达”网络服务或功能。
      </p>
      <p class="m60">
        c.通过”新石器准时达”所设cookies所取得的有关信息，将适用本协议。
      </p>
      <p class="m60">
        d.如您通过我们的网站或APP，使用了由第三方而非“新石器准时达”提供的服务时，为尽力确保您的账号安全，使您获得更安全的访问体验，我们可能会使用专用的网络协议及代理技术（以下简称“Proxy技术”）。使用Proxy技术，可以帮助您识别到我们已知的高风险站点，减少由此引起的钓鱼、账号泄露等风险，同时更有利于保障您和第三方的共同权益，阻止不法分子篡改您和您希望访问的第三方之间正常服务内容，包括：不安全路由器、非法基站等引起的广告注入、非法内容篡改。在此过程中，我们也可能会获得和保存您设备的IP地址、硬件ID以及您访问的页面位置。
      </p>
  
      <p>三、我们如何存储和保护信息</p>
      <p class="m30">3.1. 您个人信息的保存</p>
      <p class="m60">
        3.1.1.
        我们将在中华人民共和国境内收集的个人信息存储在中华人民共和国境内。如您使用跨境业务，且需要向境外传输您的个人信息的，我们会按照法律法规和相关监管部门的规定执行，对个人信息出境目的、个人信息类型、接收方、安全保障措施等进行说明，并要求接收方按照法律法规和相关监管部门的规定并采取相关保密和安全措施来处理这些个人信息。
      </p>
      <p class="m60">
        3.1.2.
        我们仅在本政策所述目的所必须期间和法律法规及监管规定的时限内保存您的个人信息。我们将会按照《支付机构反洗钱和反恐怖融资管理办法》规定的期限保存您的身份资料、交易记录。
      </p>
      <p class="m30">3.2. 数据安全技术措施</p>
      <p class="m30">
        我们会采用符合业界标准的安全防护措施以及行业内通行的安全技术来防止您的个人信息遭到未经授权的访问、修改，避免您的个人信息泄露、损坏或丢失，这些技术措施包括：
      </p>
      <p class="m60">
        （1）采取加密技术对您的个人信息进行加密存储，从安全管理、策略、过程、网络体系结构等诸多方面保障交易及个人信息安全。
      </p>
      <p class="m60">
        （2）我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保您的个人信息在传输过程中的安全。
      </p>
      <p class="m60">
        （3）在使用您的个人信息时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内的多种数据脱敏技术增强个人信息在使用中的安全性。
      </p>
      <p class="m60">（4）我们会采用恶意代码防范措施保护您的个人信息安全。</p>
  
      <p class="m30">3.3. 为保护个人信息采取的其他安全措施</p>
      <p class="m30">
        我们通过建立数据分类分级、数据安全管理规范、安全开发规范来管理规范个人信息的存储和使用：
      </p>
      <p class="m60">
        （1）我们采用严格的数据访问权限和多重身份认证技术控制和保护个人信息，通过与信息接触者签署严格的保密协议、监控和审计机制来对数据进行全面安全控制，避免数据被违规使用。
      </p>
      <p class="m60">
        （2）我们采用代码安全检查、数据访问日志分析技术进行个人信息安全审计。
      </p>
  
      <p class="m60">
        （3）我们建立了信息安全委员会并下设信息安全小组，由各小组信息安全专员负责个人信息安全事务。
      </p>
      <p class="m60">
        （4）我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
      </p>
  
      <p class="m30">3.4. 安全事件处置</p>
      <p class="m60">
        （1）为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
      </p>
      <p class="m60">
        （2）如果不幸发生个人信息安全事件，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
      </p>
      <p class="m30">
        3.5.
        如果我们终止服务或运营，我们会将终止服务或运营的通知以逐一送达或公告的形式通知您。我们会在终止服务或运营后及时停止继续收集个人信息，并对您的个人信息进行删除或匿名化处理。
      </p>
  
      <p>四、我们如何使用信息</p>
      <p class="m30">
        为了向您提供服务及提升服务质量，或保障您的账户和资金安全，请你认真阅读以下说明：
      </p>
      <p class="m30">（1）实现本协议中“我们如何收集信息”所述目的；</p>
      <p class="m30">
        （2）“新石器准时达”不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和“新石器准时达”（含“新石器准时达”关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料；
      </p>
      <p class="m30">
        （3）“新石器准时达”亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何“新石器准时达”平台用户如从事上述活动，一经发现，“新石器准时达”有权立即终止该服务；
      </p>
      <p class="m30">（4）邀请您参与我们服务有关的客户调研；</p>
      <p class="m30">
        （5）我们会采取脱敏、去标识化等方式对您的信息进行综合统计、分析加工，以便为您提供更加流畅及便捷的服务，或帮助我们评估、改善或设计服务及运营活动；
      </p>
  
      <p>五、我们如何对外提供信息</p>
      <p class="m30">（一）业务共享</p>
      <p class="m30">
        我们承诺对您的信息进行严格保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，第三方包括我们的关联公司、合作金融机构以及其他合作伙伴。如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施，并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意，或确认第三方已经征得您的同意。
      </p>
      <p class="m60">
        （1）为了让您完成交易、实现交易目的，我们需要向您的交易相对方提供交易号与相关支付信息，同时您的交易相对方可在付款过程中或付款成功后查看您的部分注册信息；
      </p>
      <p class="m60">
        （2）某些产品或服务可能由第三方提供或由我们与第三方共同提供，因此，只有共享您的信息，才能提供您需要的产品或服务；
      </p>
      <p class="m60">
        （3）如您选择参与我们和第三方联合开展的抽奖、竞赛或类似推广活动，我们可能与其共享活动过程中产生的、为完成活动所必要的信息，以便第三方能及时向您发放奖品或为您提供服务，我们会依据法律法规或国家标准的要求，在活动规则页面或通过其他途径向您明确告知需要向第三方提供何种信息；
      </p>
      <p class="m60">
        （4）事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。
      </p>
  
      <p class="m30">（二）投诉处理</p>
      <p class="m30">1、投诉</p>
      <p class="m30">
        当您投诉他人或被他人投诉时，为了保护您及他人的合法权益，维护绿色购物环境，我们可能会将您的姓名及有效证件号码、联系方式、投诉相关内容提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。
      </p>
      <p class="m30">2、转让</p>
      <p class="m30">
        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
      </p>
      <p class="m60">（1）事先获得您的明确同意与授权；</p>
      <p class="m60">（2）根据法律法规或强制性的行政或司法要求；</p>
      <p class="m60">
        （3）在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本协议的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明确同意。
      </p>
      <p class="m30">3、公开披露</p>
      <p class="m30">
        在如下情况下，“新石器准时达”将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
      </p>
      <p class="m60">（1）经您事先同意，向第三方披露；</p>
      <p class="m60">
        （2）为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
      </p>
      <p class="m60">
        （3）根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
      </p>
      <p class="m60">
        （4）如您出现违反中国有关法律、法规或者“新石器准时达”服务协议或相关规则的情况，需要向第三方披露；
      </p>
      <p class="m60">
        （5）如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
      </p>
      <p class="m60">
        （6）在“新石器准时达”平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，“新石器准时达”有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
      </p>
      <p class="m60">
        （7）其它“新石器准时达”根据法律、法规或者网站协议认为合适的披露。
      </p>
  
      <p class="m30">4、委托处理</p>
      <p class="m30">
        为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的我们的关联公司或其他专业机构代表我们来处理信息。我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，受托公司不再保存个人信息。我们承诺对此负责。
      </p>
  
      <p class="m30">
        5、根据相关法律法规及国家标准，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息无需征得您的同意：
      </p>
      <p class="m60">（1）与履行法律法规及行业主管部门规定的义务相关的；</p>
      <p class="m60">（2）与国家安全、国防安全直接相关的；</p>
      <p class="m60">（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p class="m60">（4）与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
      <p class="m60">
        （5）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      </p>
      <p class="m60">（6）您自行向社会公众公开的个人信息；</p>
      <p class="m60">
        （7）从合法公开披露的信息中收集的个人信息，例如：合法的新闻报道、政府信息公开等渠道。
      </p>
  
      <p class="m30">（三）账号注销</p>
      <p class="m30">我们将通过新石器准时达APP为您提供产品及服务，如您需要注销您的账户或服务，可通过以下方式进行注销：</p>
      <p class="m60">1、打开新石器准时达APP，可通过访问个人中心图标进入设置页面，点击底部用户注销按钮，进行账户注销操作，确认后无需经过审核，账号将立即被注销</p>
      <p class="m60">2、在您主动注销账户或服务后，我们将停止为您提供产品或服务，并根据适用法律的要求删除或匿名化处理您的信息，请您确认好注销后可能遇到的问题：</p>
      <p class="m60">（1）、注销后不能恢复</p>
      <p class="m60">（2）、订单及各项权益都将无法使用</p>
      <p class="m60">（3）、注销后各项资料将不再保存</p>
      <p class="m60">（4）、您应确保您有权决定该账号的注销事宜，不侵犯任何第三方的合法权益，如因此引发任何争议，由您自行承担</p>
  
      <p>六、对第三方责任的声明</p>
      <p class="m30">
        请您注意，您的交易相对方、您访问的第三方网站经营者、通过“新石器准时达”接入的第三方服务和由“新石器准时达”处接收您的个人信息的第三方可能有自己的隐私权保护协议；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie或网络Beacon，这些Cookie或网络Beacon不受我们的控制，且它们的使用不受本协议的约束。我们会努力去要求这些主体对您的个人信息采取保护措施，建议您与他们联系以获得关于他们的隐私权协议的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
      </p>
      <p class="m30">
        为了使您能够在使用本平台时接收本平台的信息推送、获取无人车位置信息、保障您的账号安全等，本平台中集成了由第三方公司开发、提供的软件开发包（SDK），这些软件开发包（SDK）在实现功能时需要采集部分信息才可实现，“新石器准时达”会对SDK或其他类似的应用程序进行严格的安全检测，并要求合作伙伴采取严格的数据保护措施，切实保障您的合法权益。具体如下：
      </p>
      <p class="m30">名称：高德开发平台定位SDK</p>
      <p class="m30">获取信息：获取设备位置权限。</p>
      <p class="m30">
        使用目的：便于用户在“新石器准时达”附近的车或其他相关服务应用定位用户位置。
      </p>
      <p class="m30">
        使用范围：“新石器准时达”中需要获取用户位置的应用，如附近的车。
      </p>
      <p class="m30">名称：友盟+推送SDK</p>
      <p class="m30">获取信息：设备信息</p>
      <p class="m30">
        使用目的：通过相关设备信息和用户信息绑定，便于识别用户唯一性，完成精准推送
      </p>
      <p class="m30">使用范围：“新石器准时达”运行时</p>
      <p class="m30">名称：友盟+统计SDK</p>
      <p class="m30">获取信息：设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM卡IMSI/ICCID）、位置信息、网络信息、应用安装列表</p>
      <p class="m30">使用目的：进行APP运营统计与分析，如安装信息和报错日志等相关运行时问题统计，便于定位并修复线上问题</p>
      <p class="m30">使用范围：“新石器准时达”运行时</p>
      <p class="m30">名称：微信SDK</p>
      <p class="m30">获取信息：无</p>
      <p class="m30">使用目的：通过微信APP完成移动支付</p>
      <p class="m30">使用范围：“新石器准时达”移动支付场景</p>
      <p class="m30">名称：支付宝SDK</p>
      <p class="m30">获取信息：无</p>
      <p class="m30">使用目的：通过支付宝APP完成移动支付</p>
      <p class="m30">使用范围：“新石器准时达”移动支付场景</p>
  
      <p>七、“新石器准时达”权限获取说明</p>
      <p class="m30">
        我们可能会收集关于您使用产品和/或服务（包括使用方式）过程中的信息，此类信息包括：
      </p>
      <p class="m30">（1）设备信息</p>
      <p class="m60">
        为了提供更好的产品和/或服务并改善用户体验，我们会收集<span class="fWeight">常用设备属性信息</span>（Android ID、IDFA、经度、纬度、系统平台、系统版本、手机品牌、手机型号，软件版本等）。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将您的设备信息或电话号码与您的"新石器准时达"帐户相关联。
      </p>
      <p class="m60">
        <span class="fWeight">*其中敏感信息</span>Android ID情况说明：
      </p>
      <p class="m60">
        使用目的：保障账号安全及交易安全，我们可能使用或整合您的账号信息、设备信息，这类信息是必须收集的基础信息，用来综合判断您对账号及交易风险、进行身份验证、安全防范、欺诈检测，若您不提供该信息，我们无法确保我们向您提供的服务的安全性。
      </p>
      <p class="m60 fWeight">
        使用方式：启动app或支付交易时
      </p>
      <p class="m60">
        <span class="fWeight">使用范围：</span>“新石器准时达APP”运行时
      </p>
      <p class="m30">（2）日志信息</p>
      <p class="m60">
        当您使用“新石器准时达”时，我们的服务器会自动记录一些信息，例如您对“新石器准时达”的使用情况，包括访问服务的日期、时间、时长等。
      </p>
      <p class="m30">（3）位置信息</p>
      <p class="m60">
        使用具有定位功能的“新石器准时达”应用服务时，当您开启设备定位功能并使用我们基于位置提供的相关服务（查找附近的车）时，我们可能会收集和处理有关您实际所在位置的信息，以使得您不需要手动输入自身地理坐标就可获得相关服务。您可以通过关闭定位功能，停止对您的地理位置信息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。
      </p>
      <p class="m30">（4）相册信息</p>
      <p class="m60">
        当您使用扫一扫服务时（扫码付款），我们可能会读取您的相册信息，以使得您快速选择上传或读取所需照片。您可以通过关闭相册读取权限功能，停止对您的相册信息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。
      </p>
  
      <p>八、本协议的适用及更新</p>
      <p class="m30">本协议自您以点击或其他方式确认同意之时生效。</p>
      <p class="m30">
        我们可能适时修订本协议内容。如该等变更会导致您在本协议项下权利的实质减损，我们将在变更生效前，通过在页面显著位置、APP推送通知、弹窗提示、发送邮件/短消息等方式提示通知您。我们会在本页面上发布对本隐私协议所做的任何变更。
      </p>
      <p class="m30">
        如您在本协议更新生效后继续使用“新石器准时达”服务，即表示您已充分阅读、理解并接受更新后的协议并愿意受更新后的协议约束。
      </p>
      <p class="m30">本隐私协议所指的重大变更包括但不限于：</p>
  
      <p class="m60">
        1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <p class="m60">2.个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p class="m60">3.您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p class="m60">4.其他可能对您的个人信息权益产生重大影响的变化时。</p>
      <p class="m30">
        您可以在“新石器准时达”官方APP查看本协议。我们鼓励您在每次使用“新石器准时达”时都查阅我们的隐私协议。
      </p>
  
      <p>九、联系我们</p>
      <p class="m30">
        1.在使用“新石器准时达”过程中，对本协议存在任何疑问，或者存在损害您个人权益的情况，您可以随时联系客服，400-666-7876，我们将以最快的速度解答您的疑问解决您反馈的问题。
      </p>
      <p class="m30 fweight">
        2.我们还设立了个人信息保护的专职人员，您可以通过<span class="fblue">chenke@neolix.cn</span>与其联系，办公地点：北京市朝阳区恒通商务园b37-c；当前专职人员仅可回复本政策及与个人信息相关的问题。
      </p>
      <p class="m30">本协议中关键词说明</p>
  
      <p>附录1：定义</p>
      <p class="m30">本隐私协议中使用的特定词语，具有如下含义：</p>
      <p class="m30">1.“我们”或“新石器准时达”，指的是“新石器准时达APP”。</p>
      <p class="m30">2.“您”，指“新石器准时达”注册用户。</p>
      <p class="m30">3.“新石器准时达平台”，指的是“新石器准时达”客户端。</p>
      <p class="m30">
        4.“新石器准时达关联方、合作方”，指我们的关联公司、合作伙伴及其他受信任的第三方供应商、服务商及代理商。
      </p>
      <p class="m30">
        5.本协议中的“身份要素”是指：“新石器准时达”用于识别您身份的信息要素，包括：您的“新石器准时达”登录名、短信校验码、电话号码、手机号码及生物识别信息。
      </p>
      <p class="m30">
        6.本协议中的“个人敏感信息”是指：个人生物识别信息、交易信息、精准定位信息等个人信息。
      </p>
      <p class="m30">
        7.“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本隐私协议之目的，不包含香港特别行政区、澳门特别行政区和中国台湾地区。
      </p>
      <p class="m30 fweight">
        8.设备属性信息：包括设备标识符（Android ID、IDFA及其他设备相关信息）
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: "UserAgreement",
    components: {},
    data() {
      return {};
    },
  
    methods: {},
  
    created: function () {},
  };
  </script>
  
  <style scoped lang="scss">
  .contain {
    width: 100%;
    height: 100%;
    padding: 0.3rem;
    padding-top: 0;
    font-family: PingFangSC-Regular;
    font-size: 0.26rem;
    letter-spacing: 0;
    overflow: scroll;
      @include font_color("agreement_color");
    @include background_color("agreement_bg");
    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
    .m30 {
      margin-left: 0.6rem;
    }
    .m60 {
      margin-left: 1.2rem;
    }
  
    .fweight {
      font-weight: 700;
    }
    .fblue {
      color: #4e72b8;
    }
  }
  </style>
  